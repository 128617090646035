import React from "react";
import { graphql, Link, PageProps, useStaticQuery } from "gatsby";

import Layout from "../components/Layout";
import SEO from "../components/Seo";
import PageTitle from "../components/PageTitle";
import Typography from "@material-ui/core/Typography";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import BlogPostList from "../components/BlogPostList";
import Paper from "@material-ui/core/Paper";
import SignUpForm from "../components/SignUpFormControl";
import Box from "@material-ui/core/Box";
import Section from "../components/Section";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            maxWidth: 700,
            margin: "0 auto",
            paddingBottom: 60,
        },
    })
);

const description = "Here, you can explore the writings of Tim Edgar.";

const Blog: React.FC<PageProps> = (props) => {
    const classes = useStyles();

    return (
        <Layout section="blog">
            <SEO title="Blog" description={description} path={props.path} />
            <Section content first last>
                <PageTitle title="Blog" />

                <p>
                    My writing aims to explore the insights and discoveries that
                    I've gained over my career. Some topics will be about fields
                    I've worked in such as data or the cloud. Other topics will
                    be strategies that have enabled me to be more effective in
                    the workplace.
                </p>
                <p>
                    I typically write a few posts a month published here and
                    through my mailing list.{" "}
                    <Link to="/signup">Join my mailing list</Link> to get new
                    posts delivered to your inbox.
                </p>

                <BlogPostList />
            </Section>
        </Layout>
    );
};

export default Blog;
